import * as React from "react";
import Container, {
  CheckboxBox,
  CheckIcon,
  PillInput,
  InputWrapper,
  LabelText,
  CheckboxContainer,
} from "./styles";
import type { TypeCheckboxProps } from "./types/CheckboxTypes";

export default class Checkbox extends React.Component<TypeCheckboxProps> {
  el: HTMLInputElement | null | undefined;
  static defaultProps = {
    indeterminate: false,
    disabled: false,
  };

  componentDidUpdate(prevProps: TypeCheckboxProps) {
    if (prevProps.indeterminate !== !!this.props.indeterminate && this.el) {
      this.el.indeterminate = !!this.props.indeterminate;
    }
  }

  componentDidMount() {
    if (this.el) {
      this.el.indeterminate = !!this.props.indeterminate;
    }
  }

  handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange(event);
  };

  render() {
    const {
      id,
      value,
      name,
      label,
      labelProps,
      checked,
      disabled,
      indeterminate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      ariaLabel,
      appearance = "default",
      qa = {},
      tabIndex,
      inputProps = {},
      ...rest
    } = this.props;
    const isPill = appearance === "pill";
    // @ts-notes - tabIndex requires a number but can be a string
    const tabIndexInt =
      typeof tabIndex === "string" ? parseInt(tabIndex, 10) : tabIndex;

    // TODO: Refactor pill checkboxes to use fewer elements for performance gains
    if (!isPill) {
      return (
        <CheckboxContainer
          as={label && "label"}
          disabled={disabled}
          indeterminate={indeterminate}
          checked={checked}
          {...rest}
        >
          <input
            type="checkbox"
            id={id}
            aria-label={ariaLabel}
            value={value}
            name={name}
            checked={checked}
            ref={(el) => (this.el = el)}
            disabled={disabled}
            onChange={this.handleChange}
            data-qa-checkbox={id}
            data-qa-checkbox-ischecked={
              indeterminate ? "indeterminate" : checked === true
            }
            data-qa-checkbox-isdisabled={disabled === true}
            tabIndex={tabIndexInt}
            {...qa}
            {...inputProps}
          />
          {label && (
            <LabelText {...labelProps} disabled={disabled}>
              {label}
            </LabelText>
          )}
        </CheckboxContainer>
      );
    }

    return (
      <Container as={label && "label"} {...rest}>
        <InputWrapper appearance={appearance}>
          <PillInput
            type="checkbox"
            id={id}
            aria-label={ariaLabel}
            value={value}
            name={name}
            checked={checked}
            ref={(el) => (this.el = el)}
            disabled={disabled}
            onChange={this.handleChange}
            data-qa-checkbox={id}
            data-qa-checkbox-ischecked={
              indeterminate ? "indeterminate" : checked === true
            }
            data-qa-checkbox-isdisabled={disabled === true}
            tabIndex={tabIndexInt}
            {...qa}
            {...inputProps}
          />
          <CheckboxBox>
            <CheckIcon
              name={indeterminate ? "minus-solid" : "check-solid"}
              size="mini"
              fixedWidth
            />
          </CheckboxBox>
        </InputWrapper>
      </Container>
    );
  }
}
