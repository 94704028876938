import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing, pill } from "@sproutsocial/seeds-react-mixins";
import Box from "@sproutsocial/seeds-react-box";
import Icon from "@sproutsocial/seeds-react-icon";
import Text from "@sproutsocial/seeds-react-text";
import type { TypeCheckboxProps } from "./types/CheckboxTypes";

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;

  ${COMMON}
`;
Container.displayName = "Checkbox";

export const InputWrapper = styled(Box)<Pick<TypeCheckboxProps, "appearance">>`
  box-sizing: border-box;
  position: relative;
  width: ${(props) => props.theme.space[400]};
  height: ${(props) => props.theme.space[400]};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.appearance === "pill" &&
    css`
      background-color: transparent;
      transition: all ${(props) => props.theme.duration.fast}
        ${(props) => props.theme.easing.ease_inout};
      /* This solution is temporary. DS-1095 */
      mix-blend-mode: ${props.theme.mode === "dark" ? "screen" : "multiply"};
      ${pill}

      &:hover {
        background-color: ${props.theme.colors.app.background.base};
      }

      ${PillInput} {
        ${pill}
      }
    `}
`;
InputWrapper.displayName = "InputWrapper";

export const CheckboxBox = styled.div`
  box-sizing: border-box;
  position: relative;
  width: ${(props) => props.theme.space[400]};
  height: ${(props) => props.theme.space[400]};
  border: 1px solid ${(props) => props.theme.colors.form.border.base};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.form.background.base};
  transition: border-color ${(props) => props.theme.duration.fast}
      ${(props) => props.theme.easing.ease_in},
    background-color ${(props) => props.theme.duration.fast}
      ${(props) => props.theme.easing.ease_in};
  pointer-events: none;
`;
CheckboxBox.displayName = "CheckboxBox";

export const CheckIcon = styled(Icon).attrs({ "aria-hidden": true })`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: all
    ${({ theme }) => `${theme.duration.fast} ${theme.easing.ease_inout}`};

  &,
  & .Icon-svg {
    width: 10px;
    height: 10px;
    line-height: 10px;
  }
`;
CheckIcon.displayName = "CheckIcon";

export const PillInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  appearance: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 4px;

  /* IE 11 overrides */
  &::-ms-check {
    display: none;
  }

  &:focus {
    ${focusRing}
  }

  ${(props) =>
    !props.checked &&
    css`
      &:hover,
      &:focus {
        ~ ${CheckboxBox} ${CheckIcon} {
          opacity: ${props.disabled ? 0 : 1};
          color: ${props.theme.colors.form.border.base};
        }
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      ~ ${CheckboxBox} {
        border-color: ${props.theme.colors.form.border.selected};
        background-color: ${props.theme.colors.form.background.selected};
      }
      ~ ${CheckboxBox} ${CheckIcon} {
        opacity: 1;
        color: ${props.theme.colors.icon.inverse};
      }
    `}

    ${(props) =>
    props.disabled &&
    css`
      ~ ${CheckboxBox} {
        opacity: 0.4;
      }
      ~ ${CheckboxBox} ${CheckIcon} {
        opacity: ${props.checked ? 1 : 0};
      }
    `}
`;
PillInput.displayName = "PillInput";

export const LabelText = styled(Text)<Pick<TypeCheckboxProps, "disabled">>`
  margin-left: ${(props) => props.theme.space[300]};
  font-size: ${(props) => props.theme.typography[200].fontSize};
  line-height: 1;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props.theme.colors.text.headline};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;
LabelText.displayName = "LabelText";

const checkSVG = (color: string) => (
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.378 11.37L2 7.59l1.438-1.286L5.374 8.47l5.185-5.84L12 3.91l-6.622 7.46"
      fill={color}
    />
  </svg>
);

const indeterminateSVG = (color: string) => (
  <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
    <rect width="10" height="2" x="2" y="6" rx="1" ry="1" fill={color} />
  </svg>
);

const getIcon = (type: "indeterminate" | "check", color: string) => {
  const escapedColor = encodeURIComponent(color);
  const icon =
    type === "indeterminate"
      ? indeterminateSVG(escapedColor)
      : checkSVG(escapedColor);
  return icon;
};
/* stylelint-disable eslint-disable */
export const CheckboxContainer = styled.span<
  Pick<TypeCheckboxProps, "indeterminate" | "checked" | "disabled">
>(
  (props) => css`
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    transition: all ${props.theme.duration.fast} ${props.theme.easing.ease_in};

    @supports (-webkit-appearance: none) {
      &:before {
        /* The following line cannot have multiple lint exclusions, otherwise it will not work
        the svg data url MUST be single quoted */
        /* prettier-ignore */
        content: url('data:image/svg+xml;utf8,${renderToStaticMarkup(
          getIcon(
            props.indeterminate ? "indeterminate" : "check",
            props.checked
              ? props.theme.colors.form.background.base
              : props.theme.colors.form.border.base
          )
        )}');
        opacity: ${props.checked ? 1 : 0};
        position: absolute;
        width: ${props.theme.space[400]};
        height: ${props.theme.space[400]};
        text-align: center;
        transform: translateY(1px);
        line-height: 1;
        margin: auto;
        pointer-events: none;
        transition: ${props.theme.duration.fast}
          ${props.theme.easing.ease_inout};
      }

      &:hover:before {
        opacity: ${props.disabled && !props.checked ? 0 : 1};
      }

      ${props.disabled &&
      css`
        opacity: 0.4;
      `}

      input[type='checkbox'] {
        box-sizing: border-box;
        appearance: none;
        margin: 0;
        padding: 0;
        width: ${props.theme.space[400]};
        height: ${props.theme.space[400]};
        border: 1px solid ${props.theme.colors.form.border.base};
        border-radius: 4px;
        background-color: ${props.theme.colors.form.background.base};
        transition: all ${props.theme.duration.fast}
          ${props.theme.easing.ease_in};
        cursor: ${props.disabled ? "not-allowed" : "pointer"};
        flex-shrink: 0;

        &:not(:checked) {
          ${!props.indeterminate &&
          css`
            border-color: ${props.theme.colors.form.border
              .base} !important; /* We don't want the focus ring to remove the border */
            background-color: ${props.theme.colors.form.background.base};
          `}
        }

        &:checked {
          border-color: ${props.theme.colors.form.border.selected};
          background-color: ${props.theme.colors.form.background.selected};
        }

        ${props.indeterminate &&
        props.checked &&
        css`
          border-color: ${props.theme.colors.form.border.selected} !important;
          background-color: ${props.theme.colors.form.background
            .selected} !important;
        `}

        &:focus {
          ${focusRing}
        }
      }
    }

    ${COMMON}
  `
);

export default Container;
